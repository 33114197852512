@import './CssGlobalVariables/globalVariables.css';

.root {
  display: flex !important;
}

.companyLogo {
  width: var(--logoWidth);
  height: var(--logoHeight);
  background-repeat: no-repeat;
  background-size: contain;
}

.appBar {
  background-color: var(--headerColor) !important;
  z-index: 1201 !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.appBarShift {
  background-color: var(--headerColor);
  width: calc(100% - 260px) !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
  margin-left: 240px !important;
}

.menuButton {
  margin-right: 36px !important;
}

.hide {
  display: none !important;
}

.drawer {
  flex-shrink: 0 !important;
  white-space: nowrap !important;
}

.drawer.drawerOpen {
  width: 240px !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.toolbar {
  display: flex !important;
  padding: 0 8px !important;
  min-height: 63px !important;
  align-items: center !important;
  justify-content: space-between !important;
}

.content {
  padding: 80px 0 !important;
  flex-grow: 1 !important;
}

.MuiDrawer-paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
}

.drawer.drawerClose {
  width: 57px !important;
  overflow-x: hidden !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}


.drawerOpen1 {
  width: 260px !important;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.drawerClose2 {
  width: 65px;
  overflow-x: hidden !important;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important;
}

.headerCurrentUser {
  display: flex;
  align-items: center;
}

.headerCurrentUser div {
  margin-right: 13px;
  font-size: 16px;
}

.headerCurrentUser a {
  text-decoration: none;
  /* color: aliceblue; */
}

.headerCurrentUser span {
  text-transform: capitalize !important;
}

.body {
  max-width: 1365px !important;
  margin: 0 auto !important;
}

.parentLabel {
  width: 140px;
  white-space: break-spaces;
}

.toggle {
  margin-left: -18px;
}

.linkBody a {
  font-size: 16px;
  text-decoration: none;
  color: #000;
}

.linkBody {
  background-color: #fff;
  position: absolute;
  margin-top: 9px;
  left: 120px;
  width: 240px;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%);
}

.accountBody {
  margin-top: 50px;
  margin-left: -30px;
}

.profileLink {
  text-decoration: none;
  color: #000;
}

.accountButton svg {
  margin-right: 15px;
}

.accountButton {
  border-radius: unset !important;
  width: 100%;
}

.accountButton span {
  justify-content: unset !important;
  padding: 5px 10px;
}

.linkItem {
  padding: 10px;
}

.linkBody div .linkItem:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.linkList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconsLink {
  color: #fff;
  font-size: 32px !important;
}

.linkOpen {
  transform: none;
  transition: opacity 326ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 217ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  top: 42px;
  left: 1056px;
  transform-origin: 0 26px;
}

.linkClose {
  display: none;
}

.headerTitle {
  display: flex;
  align-items: center !important; 
  width: 100%;
  justify-content: space-between;
}

.headerTitle div {
  align-items: center;
}

.hederListBody {
  display: flex;
  align-items: center;
}

.rightSideLogout {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}

.rightSideLogout div {
  align-items: center;

}

.subMenu {
  padding-left: 30px !important;
  white-space: break-spaces;
  display: flex;
  align-items: baseline;
}

.subMenuGen {
  padding-top: 0;
  padding-left: 60px !important;
  white-space: break-spaces;
  align-items: baseline;
}

.sub2MenuGenBody {
  display: contents;
}

.sub2MenuGen {
  white-space: break-spaces;
}

.sub2MenuGen a {
  padding-left: 60px !important;
}

.subMenuFirstGen a {
  padding-left: 30px !important;
}

.labelReports {
  margin-left: 24px;
  margin-right: 60px;
}

.closeBody {
  width: 100% !important;
  margin: 0 20px !important;
}

.openBody {
  width: 100% !important;
  margin: 0 30px;
}

.subBody {
  padding: 0 !important;
  display: contents !important;
}

.lastLabel {
  display: contents !important;
}

.flexCustom:hover {
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

.companyName {
  border: 1px solid #efefef;
}

.companyName p {
  font-size: 20px;
  text-align: center;
  padding: 20px;
  width: 250px;
  white-space: break-spaces;
  font-family: verdana, geneva, sans-serif;
}

.displayNone {
  display: none;
}

@media (max-width: 380px) {
  .drawer.drawerClose {
    display: none !important;
  }

  .toolbar {
    min-height: 86px !important;
  }

  .content {
    padding-top: 80px !important;
  }

  .closeBody {
    margin: 0 !important;
    padding: 10px;
  }

  .headerCurrentUser b {
    font-size: 16px;
  }

  .headerCurrentUser div {
    margin-right: 12px !important;
    width: 200px;
    max-width: 200px !important;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .rightBody {
    margin-top: -25px !important;
  }

  .menuButton {
    margin-right: 22px !important;
  }
}

.subTenant span {
  padding-left: 15px !important;
}

.table {
  min-width: 700px;
}

.appbar {
  background-color: #095c75;
}

.title {
  flex-grow: 1;
  font-weight: initial;
  margin-left: 10px;
  text-transform: capitalize;
}

.head {
  display: flex;
}

.rightAction {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

.header {
  display: flex;
  align-items: center;
}

.taskTransaction {
  display: flex;
  align-items: center;
  height: 20px;
  padding: 10px;
  margin: 15px;
  text-transform: capitalize;
}

.itemMargin {
  margin-left: 2;
}

.cell {
  height: 20px;
  padding: 10px;
  align-items: center;
  margin: 15px;
  text-transform: capitalize;
}

.actions {
  width: 100px !important;
}

.tableTitle {
  display: flex;
  justify-content: space-between;
}

.tileBody {
  display: flex;
  align-items: center;
}

thead tr th {
  border-top: 1px solid #095c75;
  background-color: #f5f4f4 !important;
  border-bottom: 2px solid #9e9a9a !important;
}

.amount {
  width: 200px;
  text-align: right !important;
}

.taskLegend {
  display: flex;
  align-items: center;
}

.box {
  width: 20px;
  height: 20px;
  background: blue;
}

.circle {
  width: 10px;
  height: 8px;
  border-radius: 50%;
  background: #095c75;
  margin-right: 14px;
  margin-bottom: 4px;
  margin-left: 10px;
}

.tableDesc {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.searchBar {
  display: flex;
  align-items: center;
}

.groupTileSearch {
  display: flex;
  width: 70%;
  align-items: inherit;
  justify-content: space-between;
}

.searchBody {
  width: 630px;
  margin-top: 5px;
}

.searchList {
  display: flex;
}

.advancedSearch {
  width: 220px;
}

.searchField {
  margin-right: 10px !important;
}

.duration {
  width: 50px !important;
}

.filedAt {
  width: 90px;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

/* .paginationWidth {
  width: 170px;
} */

.showButtonTasksBody button {
  border-radius: 50px;
}

.link {
  color: black;
  text-decoration: none;
}

.link:hover {
  color: #095c75;
}

.toggleButton {
  border-radius: 50px !important;
  background-color: #095c75 !important;
  color: white !important;
  margin-left: 12px !important;
}

.checkColor {
  color: #1f9a09;
}

.clearColor {
  color: #ff0404;
}

.remarksEllipsis {
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.customHeader {
  width: 50%;
}

.customHeader th {
  border: none;
  border-bottom: none !important;
  font-weight: bold;
}

.accounCode {
  width: 160px;
  padding: 0 10px;
}

.accounName {
  width: 200px;
}

.debit {
  width: 100px;
  text-align: right !important;
}

.credit {
  width: 150px;
  text-align: right !important;
}

.customRow tr {
  width: 50% !important;
}

.customRowBody {
  padding: 0 10px !important;
}

.tableBody tr:hover {
  cursor: pointer;
}

.actionButtonHoverable {
  position: absolute;
  right: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverablePayee {
  position: absolute;
  right: 0 !important;
  margin-top: -40px;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverablePayee:hover {
  opacity: 1;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

tr.linkable:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.tableBody tr:hover + .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.actionButtonHoverable:hover .actionButtonHoverable:focus .actionButtonHoverable:active {
  opacity: 1 !important;
}

.actionButtonHoverablePayee td {
  border-bottom: none !important;
}

.tableBody tr:hover + .actionButtonHoverablePayee {
  opacity: 1;
  transition: opacity 1s;
}

.notLinkable td {
  vertical-align: baseline !important;
}

.buttonDisbursement {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.linkable td {
  pointer-events: auto !important;
  vertical-align: baseline !important;
}

.fullWitdActionHoverable {
  position: absolute;
  right: 0 !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.fullWitdActionHoverable:hover,
.fullWitdActionHoverable:focus,
.fullWitdActionHoverable:active {
  opacity: 1;
}

tr.notLinkable:hover .fullWitdActionHoverable {
  opacity: 1;
  transition: opacity 1s;
}

tr.fullWitdActionHoverable:hover {
  opacity: 1;
  transition: opacity 1s;
}

.fullWitdActionHoverable td {
  border-bottom: none !important;
}

.notLinkable td:last-child {
  pointer-events: visible !important;
  cursor: default;
  text-align: right;
}

.linkable td:last-child {
  pointer-events: visible !important;
  cursor: default;
  text-align: right;
}

.employeeNameWidth {
  width: 50% !important;
}

.perPage {
  margin-top: -30px;
  display: flex;
}

.perPageSelect div {
  height: 30px !important;
  padding-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.resultsLabel {
  font-weight: initial;
  margin-top: -15px;
}

.perPageSelect [aria-haspopup="listbox"]:focus {
  background: none;
}

@media (max-width: 380px) {
  .perPage {
    margin-top: 0 !important;
  }

  .paginationWidth {
    width: 170px;
  }

  .tableTitle {
    margin-bottom: 10px;
  }
}

.tableBodyHeight {
  max-height: 100% !important;
  padding: 0 10px;
  overflow: hidden !important;
}

.noDisplay {
  display: none !important;
}

.pdfHeader {
  margin-top: 15px;
  margin-left: 10px;
  margin-bottom: 15px;
}

.simpleTableBody {
  margin: 0 10px !important;
}

@media print {
  thead {
    display: table-row-group !important;
  }
}

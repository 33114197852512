.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  margin-top: 10px;
  border-radius: 20px !important;
}

.coverPhoto img {
  width: 100%;
  height: 450px;
}

.profilePic {
  position: absolute;
  top: 60%;
  display: flex;
  margin-left: 40px;
}

/*.profilePic svg {*/
/*  font-size: 8.1875rem;*/
/*}*/

.profileName {
  margin-top: 36px;
  color: #1f5254;
  font-size: 20px;
}

.pulse {
  margin-top: 100px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #1f9a09;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
  display: flex;
  margin-left: 88px;
  position: absolute;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.profileAvatar {
  width: 128px !important;
  height: 120px !important;
}

.profileAvatar img {
  width: 100%;
  height: 100%;
}

.editProfile {
  margin-left: 176px;
  margin-top: 45px;
  font-size: 15px;
}

.uploadInput {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}

.uploadButton {
  background: #a2a1a1 !important;
}

.profilePicAvatarForm {
  margin: 0 auto;
  width: 100px !important;
  height: 100px !important;
  margin-bottom: 10px;
}

.labelAvatar {
  margin-left: 10px;
}

.editAvatarButton button {
  position: absolute;
  top: 87px;
  left: -9px;
  background: #efefef;
  opacity: 0;
}

.editAvatarButton button:hover {
  background: #efefef !important;
  opacity: 1;
}

.profileAvatar:hover + .editAvatarButton button {
  opacity: 1;
  transition-delay: 800ms;
}

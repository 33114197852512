.home {
  color: #fff;
}

.textFields {
  margin: 10px 0 !important;
}

.leftTextField {
  margin: 10px 0 !important;
  margin-right: 5px !important;
  width: 95% !important;
}

.rightTextField {
  margin: 10px 0 !important;
  margin-left: 5px !important;
  width: 95% !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  margin: 5px !important;
  border-radius: 20px !important;
}

.flex {
  display: flex !important;
}

.detailList {
  display: flex;
  margin: 5px;
  text-transform: capitalize;
}

.flexGroup > div {
  width: 274px !important;
  margin-right: 10px;
}

.tabListBody > div {
  padding-top: 0 !important;
}

.detailList div:first-child {
  width: 180px;
}

.titleBack {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleBack div:last-child {
  float: inline-end;
}

.errorMessage {
  color: #f00;
  font-size: 12px;
  margin: 0 !important;
  margin-top: -8px !important;
  margin-left: 10px !important;
}

.leftError {
  width: 95% !important;
  color: #f00;
  font-size: 12px;
  margin: 0 !important;
  margin-top: -8px !important;
  margin-left: 10px !important;
}

.rightError {
  width: 95% !important;
  color: #f00;
  font-size: 12px;
  margin: 0 !important;
  margin-top: 0 !important;
  margin-top: -8px !important;
  margin-right: -20px !important;
}

.flexGroup {
  display: flex;
}

.tabListTitle {
  font-weight: bold;
}

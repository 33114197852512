
.Bodylinear {
  padding: 0;
  margin-top: -16px;
  margin-left: -25px;
  width: 105%;
}

.modelName {
  display: none;
}

.colorLiner {
  background: #f07808 !important;
}

.colorLiner div:first-child {
  background: #9c4a07 !important;
}

.colorLiner div:last-child {
  background: #f07808 !important;
}

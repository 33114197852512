
.home {
  color: #fff;
}

.textFields {
  margin: 10px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  border-radius: 20px !important;
  margin: 5px;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.switchButton {
  margin-left: 15px;
}

.group2Textfield {
  display: flex;
}

.group2Textfield .textFields {
  margin: 10px 5px !important;
}

.autoCompleteField {
  width: 100%;
  margin: 10px 5px !important;
}

.amountBody {
  width: 100%;
  margin: 0 10px 0 0 !important;
}

.amountBody input {
  text-align: right !important;
}

.amountInWord {
  text-transform: capitalize;
}

.expenseTypeBody {
  width: 100%;
  margin: 0 10px 0 0;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  align-items: center;
  background: cadetblue;
  color: aliceblue;
  font-weight: 600;
  padding: 10px;
  margin-top: 10px;
}

.showTitleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detailsBody {
  display: flex;
  font-size: 16px;
}

.leftShowDetails {
  margin-right: 10px;
}

/* .rightShowDetails {
  width: 50%;
} */

.showTitleHeader h2 {
  margin: 0;
}

.detailList {
  display: flex;
  font-size: 16px;
}

.tabListBody > div {
  padding-top: 0 !important;
}

.detailList div:first-child {
  min-width: 255px;
}

.checkDetailsHeader {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.checkDetailsHeader h3 {
  margin: 0;
}

.groupButtonTab {
  display: flex;
  align-items: flex-start;
}

.formBody {
  height: 200px !important;
  border: dashed !important;
  display: grid;
  margin-bottom: 20px;
}

form .input {
  position: relative;
  opacity: 0;
  z-index: 99;
  height: 200px;
}

form .filelabel {
  text-align: center;
  margin-top: -109px;
}

.fileError {
  color: red;
}

.actionAttactmentButton {
  display: flex;
  justify-content: flex-end;
}

.actionAttactmentButton button {
  border-radius: 20px !important;
  margin-top: 45px;
}

.detailListCustom {
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
}

.detailListCustom .wordAnd {
  text-transform: lowercase !important;
}

.detailListCustom div:first-child {
  min-width: 255px;
}

.valueDetails {
  display: flex;
}

.groupButtonShow {
  display: flex;
  align-items: flex-start;
}

.accountWidth {
  width: 500px;
}

.debitCreditField {
  width: 200px;
}

.codeField {
  width: 100px;
}

.tableBody tr:hover {
  cursor: pointer;
}

.actionButtonHoverable {
  position: absolute;
  min-width: 65px;
  right: 40px !important;
  margin-top: -60px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverableBill {
  position: absolute;
  min-width: 65px;
  right: 10px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.tableBody:hover .actionButtonHoverableBill {
  opacity: 1;
  transition: opacity 1s;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover + .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.checkAccount input {
  color: black !important;
}

.flex {
  display: flex !important;
}

.actionJournalBody {
  display: flex;
  align-items: flex-start;
}

.errorMessage {
  color: #f00;
  font-size: 12px;
  margin: 0 !important;
  margin-top: -8px !important;
  margin-left: 10px !important;
}

.leftError {
  width: 95% !important;
  color: #f00;
  font-size: 12px !important;
  margin: 0 !important;
  margin-top: -8px !important;
  margin-left: 20px !important;
}

.rightError {
  width: 95% !important;
  color: #f00;
  font-size: 12px;
  margin: 0 !important;
  margin-top: -8px !important;
  margin-right: -20px !important;
}

.billsBtn {
  background-color: #67abbf !important;
  font-weight: bold !important;
  color: black !important;
  border-radius: 20px !important;
  margin: 5px;
}

.amount {
  text-align: right !important;
  height: 16px !important;
  padding: 10px !important;
  align-items: center !important;
  margin: 15px !important;
}

.particulars {
  width: 400px !important;
  height: 16px !important;
  padding: 10px !important;
  align-items: center !important;
  margin: 15px !important;
}

.refNo {
  width: 200px !important;
  height: 16px !important;
  padding: 10px !important;
  align-items: center !important;
  margin: 15px !important;
}

.small {
  width: 100px !important;
  height: 16px !important;
  padding: 10px !important;
  align-items: center !important;
  margin: 15px !important;
}

.amtheader {
  height: 16px !important;
  padding: 10px !important;
  align-items: center !important;
  margin: 15px !important;
}

.amtheaderlast {
  width: 29%;
}

.totalcellAmount {
  text-align: right !important;
  border-top: 3px double !important;
}

@media (max-width: 380px) {
  .tabListBody > div {
    padding: 0 !important;
  }

  .detailList div:first-child {
    min-width: 175px;
  }

  .detailListCustom div:first-child {
    min-width: 175px;
  }

  .gridBody {
    padding: 0 !important;
  }
}

.noDisplay {
  display: none !important;
}

.tableBodyHeight {
  margin: 0 20px;
}

.tabLabel {
  font-weight: 700 !important;
}

.labelDisplay h2 {
  margin: 0 !important;
}

@media print {
  footer {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }

  footer h3 {
    text-align: center;
    margin: 3px;
    font-style: italic;
    font-weight: 500;
  }
}

.signLine {
  border: 1px solid #888 !important;
  width: 200px !important;
}

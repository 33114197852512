@import '../../CssGlobalVariables/globalVariables.css';

.root {
  height: 100vh !important;
}

.image {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
}

.paper {
  margin: 110px 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.avatar {
  width: 250px;
  background-repeat: no-repeat;
  background-size: contain;
}

.form {
  width: 100%;
  margin-top: 8px;
}

.submit {
  margin: 24px 0 16px;
}

.headerName {
  color: #2b3052;
  font-size: 38px;
  margin: 0 10px 30px;
  text-align: center;
}

.forgotIcons a {
  margin: 10px 10px;
}

.textField {
  margin: 10px 0 !important;
}

.errorText {
  color: #f44336;
  font-weight: 500;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.actionButton button {
  margin-top: 10px;
  border-radius: 20px !important;
}

.errorMessage {
  color: #f00;
  font-size: 12px;
  margin: 0 !important;
}

.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.amountTextFields {
  margin: 12px 0 !important;
}

.amountTextFields input {
  text-align: right;
}

.leftTextField {
  margin-right: 5px !important;
  width: 95% !important;
}

.rightTextField {
  margin-left: 5px !important;
  width: 95% !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  margin: 5px !important;
  border-radius: 20px !important;
}

.flex {
  display: flex;
}

.detailListRemarks {
  display: flex;
  font-size: 16px;
}

.tabListBody > div {
  padding-top: 0 !important;
}

.detailListRemarks div:first-child {
  min-width: 180px;
}

.detailList {
  display: flex;
  font-size: 16px;
}

.detailListCustom div:first-child {
  min-width: 180px;
}

.gridBody {
  padding: 2px !important;
}

.buttonBody {
  display: flex;
  align-items: baseline;
}

.accountWidth {
  width: 500px;
}

.debitCreditField {
  width: 200px;
}

.detailList div:first-child {
  width: 180px;
}

.valueDetails {
  display: flex;
}

.detailListCustom {
  display: flex;
  font-size: 16px;
  text-transform: capitalize;
}

.detailListCustom .wordAnd {
  text-transform: lowercase !important;
}

.titleBack {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleBack div:last-child {
  float: end;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  font-size: 19px;
  align-items: center;
  background: cadetblue;
  color: aliceblue;
  font-weight: 600;
  padding: 0 10px;
  margin-top: 10px;
}

.actionButton a {
  text-decoration: none;
}

.paymentBody {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.paymentBody svg {
  font-size: 70px;
}

.checkDetailsHeader {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.checkDetailsHeader h3 {
  margin: 0;
}

.groupButtonTab {
  display: flex;
  align-items: flex-start;
}

.actionButtonHoverable {
  position: absolute;
  right: 10px !important;
  border-bottom: none !important;
  opacity: 0;
  z-index: 10;
}

.actionButtonHoverable td {
  border-bottom: none !important;
}

.actionButtonHoverable:hover {
  opacity: 1;
}

.tableBody tr:hover .actionButtonHoverable {
  opacity: 1;
  transition: opacity 1s;
}

.checkAccount input {
  color: #000 !important;
}

.debitCreditField input {
  text-align: right;
}

.amountTotal {
  padding-right: 25px;
  font-size: 16px;
}

button.displayNoneDelete {
  display: none !important;
}

.termsBody {
  display: flex;
  margin-top: -10px;
}

.switchBody {
  display: flex;
}

.expenseBody {
  margin-top: 11px !important;
}

.amountInWordForm {
  display: flex;
}

.amountInWordForm div:first-child {
  width: 430px;
}

@media (max-width: 380px) {
  .tabListBody > div {
    padding: 0 !important;
  }
}

.noDisplay {
  display: none !important;
}

.tableBodyHeight {
  margin: 0 20px;
}

.labelDisplay h2 {
  margin: 0 !important;
}

@media print {
  footer {
    position: fixed;
    bottom: 30px;
    right: 20px;
  }
  
  footer h3 {
    text-align: center;
    margin: 3px;
    font-style: italic;
    font-weight: 500;
  }
}

.signLine {
  border: 1px solid #888888 !important;
  width: 200px !important;
}


.home {
    color: #fff;
  }
  
  .textFields {
    margin: 10px 0 !important;
  }
  
  .actionButton {
    display: flex;
    justify-content: flex-end;
  }
  
  .actionButton button {
    border-radius: 20px !important;
    margin: 5px;
  }
  
  .error {
    color: red !important;
    margin: 0 !important;
    margin-top: -8px !important;
  }
  
  .group2Textfield {
    display: flex;
  }
  
  .group2Textfield .textFields {
    margin: 10px 5px !important;
  }
  
  .autoCompleteField {
    width: 100%;
    margin: 10px 5px !important;
  }
  
  .amountBody {
    width: 100%;
    margin: 0 10px 0 0 !important;
  }
  
  .amountBody input {
    text-align: right !important;
  }
  
  .amountInWord {
    text-transform: capitalize;
  }
  
  .expenseTypeBody {
    width: 100%;
    margin: 0 10px 0 0;
  }
  
  .headerContent {
    display: flex;
    justify-content: space-between;
    font-size: 19px;
    align-items: center;
    background: cadetblue;
    color: aliceblue;
    font-weight: 600;
    padding: 0 10px;
    margin-top: 10px;
  }
  
  .showTitleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .detailsBody {
    display: flex;
    font-size: 16px;
  }
  
  .leftShowDetails {
    width: 50%;
  }
  
  .rightShowDetails {
    width: 50%;
  }
  
  .showTitleHeader h2 {
    margin: 0;
  }
  
  .detailList {
    display: flex;
    margin: 5px;
  }
  
  .detailList div:first-child {
    min-width: 300px;
  }
  
  .checkDetailsHeader {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  
  .checkDetailsHeader h3 {
    margin: 0;
  }
  
  .groupButtonTab {
    display: flex;
    align-items: flex-end;
  }
  
  .formBody {
    height: 200px !important;
    border: dashed !important;
    display: grid;
    margin-bottom: 20px;
  }
  
  form .input {
    position: relative;
    opacity: 0;
    z-index: 99;
    height: 200px;
  }
  
  form .filelabel {
    text-align: center;
    margin-top: -109px;
  }
  
  .fileError {
    color: red;
  }
  
  .actionAttactmentButton {
    display: flex;
    justify-content: flex-end;
  }
  
  .actionAttactmentButton button {
    border-radius: 20px !important;
    margin-top: 45px;
  }
  
  .detailListCustom {
    display: flex;
    margin: 5px;
    text-transform: capitalize;
  }
  
  .detailListCustom div:first-child {
    min-width: 300px;
  }
  
  .valueDetails {
    display: flex;
  }
  
  .marginCell {
    padding-left: 30px !important;
  }

  .noDisplay {
    display: none !important;
  }

  .tableHead  {
   margin-top: 20px !important;
   text-align: center !important;
   width: 100% !important;
   margin-right: 50% !important;
  }

  .table {
    margin: 0 10px !important
  }

  @media print {
    .titleIncome {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
.home {
  color: #fff;
}

.textFields {
  margin: 12px 0 !important;
}

.actionButton {
  display: flex;
  justify-content: flex-end;
}

.error {
  color: red !important;
  margin: 0 !important;
  margin-top: -8px !important;
}

.actionButton button {
  margin-top: 10px;
  border-radius: 20px !important;
}

.flex {
  display: flex;
}
